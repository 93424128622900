import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { WorkflowExecution, WorkflowTemplate } from "@/types"
import api from "@/api"
import styles from "./TemplateList.module.scss"
import { Button } from "primereact/button"
import { Divider } from "primereact/divider"
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown"
import DisplayWorkflowTemplateList from "./DisplayTemplateWorkflowList"
import { ProgressSpinner } from "primereact/progressspinner"
import { InputText } from "primereact/inputtext"

const TemplateList = () => {
  const [workflowExecutions, setWorkflowExecutions] = useState<Record<string, WorkflowExecution>>()
  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>()
  const [activeDropdownValue, setActiveDropdownValue] = useState<string>(
    localStorage.getItem("templateFilter.activeDropdownValue") || "all",
  )
  const [search, setSearch] = useState<string>(localStorage.getItem("templateFilter.searchValue") || "")
  const navigate = useNavigate()

  interface DisplayType {
    label: string
    value: string
  }

  const displayTypes: DisplayType[] = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Visible", value: "visible" },
    { label: "Invisible", value: "invisible" },
  ]

  useEffect(() => {
    const loadExecutions = async () => {
      const executions = await api.protected.getWorkflowExecutions()
      setWorkflowExecutions(executions)
    }
    if (workflowTemplates) loadExecutions()
  }, [workflowTemplates])

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await api.protected.getWorkflowTemplates()
      setWorkflowTemplates(templates)
    }
    loadTemplates()
  }, [])

  if (!workflowTemplates || !workflowExecutions) return <ProgressSpinner />

  const groupTemplates = (templates: WorkflowTemplate[]): Record<string, WorkflowTemplate[]> => {
    return templates.reduce((groups: Record<string, WorkflowTemplate[]>, template: WorkflowTemplate) => {
      const firstWord: string = (template.displayName || "other").trim().split(" ")[0].toLowerCase()
      if (!groups[firstWord]) {
        groups[firstWord] = [template]
      } else {
        groups[firstWord].push(template)
      }
      return groups
    }, {})
  }

  const activeTemplates = workflowTemplates.filter((template) => {
    switch (activeDropdownValue) {
      case "all":
        return true
      case "active":
        return template.isEnabled
      case "inactive":
        return !template.isEnabled
      case "visible":
        return template.isVisible
      case "invisible":
        return !template.isVisible
      default:
        return false // or true based on your default case needs
    }
  })

  const filteredTemplates = Object.values(activeTemplates).filter((template) =>
    searchInTemplateObject(template, search),
  )
  const groupedTemplates = groupTemplates(filteredTemplates)

  const handleNew = () => {
    navigate("/app/templates/new")
  }
  const handleFilterChange = (event: DropdownChangeEvent) => {
    setActiveDropdownValue(event.value)
    localStorage.setItem("templateFilter.activeDropdownValue", event.value)
  }
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
    localStorage.setItem("templateFilter.searchValue", event.target.value)
  }
  const handleClear = (): void => {
    setSearch("")
    localStorage.setItem("templateFilter.searchValue", "")
  }
  return (
    <>
      <h2 className={styles.title}>Template List</h2>
      <div className={styles.filter}>
        <div className={styles.toggleBox}>
          <div className={styles.toggleGroups}>
            <div className={styles.filterLabel}>Search templates</div>
            <InputText
              type="text"
              placeholder="enter search string"
              value={search}
              tooltip="enter a string for a fulltext search or use a ':' for specific key ex.: 'handler: download'"
              tooltipOptions={{ showDelay: 300, hideDelay: 0 }}
              onChange={handleSearchChange}
            />
            <Button label="Clear Search" style={{ marginLeft: "10px" }} onClick={() => handleClear()} />
          </div>
          <div className={styles.toggleGroups}>
            <div className={styles.filterLabel} style={{ margin: "10px" }}>
              Display
            </div>
            <Dropdown
              inputId="active-filter"
              value={activeDropdownValue}
              onChange={(e) => handleFilterChange(e)}
              options={displayTypes}
              style={{ margin: "0 10px 0 0 " }}
            />
          </div>
        </div>
      </div>
      <Divider type="solid" />
      <DisplayWorkflowTemplateList groupedTemplates={groupedTemplates} workflowExecutions={workflowExecutions} />
      <Divider type="solid" />
      <Button onClick={handleNew}>New Workflow Template</Button>
    </>
  )
}

const searchInTemplateObject = (template: WorkflowTemplate, searchText: string): boolean => {
  if (!searchText.includes(":")) {
    return searchWithoutKey(template, searchText.trim().toLowerCase()) // Search normally if no key specified
  }

  const [key, value] = searchText.split(":").map((s) => s.trim().toLowerCase())
  if (typeof template !== "object" || template === null) {
    return false
  }

  if (Array.isArray(template)) {
    return template.some((entry) => searchInTemplateObject(entry, searchText))
  }

  return Object.entries(template).some(([objKey, objValue]) => {
    const matchKey = objKey.toLowerCase() === key
    if (typeof objValue === "object") {
      return matchKey || searchInTemplateObject(objValue, searchText)
    }
    const matchValue = String(objValue).toLowerCase().includes(value)
    return matchKey && matchValue
  })
}

const searchWithoutKey = (template: WorkflowTemplate, searchText: string): boolean => {
  function recursiveSearch(currentTemplate: WorkflowTemplate): boolean {
    if (Array.isArray(currentTemplate)) {
      return currentTemplate.some((entry) => recursiveSearch(entry))
    } else if (typeof currentTemplate === "object" && currentTemplate !== null) {
      return Object.values(currentTemplate).some((value) => {
        if (typeof value === "object") {
          return recursiveSearch(value)
        }
        return String(value).toLowerCase().includes(searchText)
      })
    }
    return false
  }

  return recursiveSearch(template)
}

export default TemplateList
