import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { WorkflowExecution, WorkflowTemplate } from "@/types"
import styles from "./DisplayTemplateWorkflowList.module.scss"
import { TabView, TabPanel } from "primereact/tabview"
import DisplayMetaData from "./DisplayMetaData"



const capitalizeFirstLetter = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1)
}

interface DisplayNameListProps {
  groupedTemplates: Record<string, WorkflowTemplate[]>;
  workflowExecutions: Record<string, WorkflowExecution>;
}

const DisplayWorkflowTemplateList: React.FC<DisplayNameListProps> = ({ groupedTemplates, workflowExecutions }) => {
  // Initialize activeIndex from local storage or default to 0
  const [activeIndex, setActiveIndex] = useState(() => {
    return JSON.parse(localStorage.getItem("activeTabIndex") || "0")
  })

  // Update local storage whenever activeIndex changes
  useEffect(() => {
    localStorage.setItem("activeTabIndex", JSON.stringify(activeIndex))
  }, [activeIndex])

  const changeTab = (e: any) => {
    setActiveIndex(e.index) // Update active tab index on change
  }

  let execData: WorkflowExecution | undefined
  return (
    <TabView activeIndex={activeIndex} onTabChange={changeTab} scrollable>
      {Object.keys(groupedTemplates).map((groupKey) => (
        <TabPanel header={capitalizeFirstLetter(groupKey)} key={groupKey}>
          <h2>{capitalizeFirstLetter(groupKey)}</h2>
          {groupedTemplates[groupKey].map((item: WorkflowTemplate, index: number) => {
            execData = workflowExecutions[item.name]
            return (
              <ul>
                <li className={styles.list} key={index}>
                  <Link to={`/app/templates/${item.name}`}>{item.displayName}</Link>
                  <DisplayMetaData execData={execData} />
                </li>
              </ul>
            )
          })}
        </TabPanel>
      ))}
    </TabView>
  )
}

export default DisplayWorkflowTemplateList
