import api from "@/api"
import { WorkflowTemplate } from "@/types"
import { useEffect, useState } from "react"
import WorkflowListFilter from "./WorkflowListFilter"
import WorkflowList from "./WorkflowList"
import { useSearchParams } from "react-router-dom"
import { Message } from "primereact/message"
import SearchLog from "./SearchLog"

export interface WorkflowQuery {
  workflowName: string
  workflowStatus: string
  search: string
  fileSearch: string
  date: string
}

function WorkflowListPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>([])
  const [workflowIds, setWorkflowIds] = useState<string[]>([])
  const [fileSearchMatch, setFileSearchMatch] = useState<Record<string, string>[] | undefined>([]) // to fix
  const [error, setError] = useState<string>()
  const [query, setQuery] = useState<WorkflowQuery>({
    workflowName: searchParams.get("name") || "",
    workflowStatus: searchParams.get("status") || "",
    search: searchParams.get("search") || "",
    date: searchParams.get("date") || "",
    fileSearch: searchParams.get("filesearch") || "",
  })

  useEffect(() => {
    const loadTemplates = async () => {
      const templates = await api.protected.getWorkflowTemplates()
      setWorkflowTemplates(templates)
    }
    loadTemplates()
  }, [])

  useEffect(() => runQuery(query), [])

  const runQuery = (query: WorkflowQuery) => {
    if (query.search || query.workflowName || query.date || query.workflowStatus || query.fileSearch.length >= 4) {
      api.protected.queryWorkflows(query).then((resp) => {
        if (resp.error) {
          setError(resp.error)
          setWorkflowIds([])
        } else if (resp.ids) {
          setError(undefined)
          setWorkflowIds(resp.ids!)
          setFileSearchMatch(resp.fileSearchMatches)
        }
      })
    } else {
      setWorkflowIds([])
      setError(undefined)
    }
  }

  const handleQueryChange = (query: WorkflowQuery) => {
    const { workflowName: name, workflowStatus: status, search, fileSearch, date } = query
    setSearchParams({ name, status, search, fileSearch, date })
    setQuery(query)
    runQuery(query)
  }

  const renderError = () => {
    if (error) {
      return (
        <Message
          style={{
            borderWidth: "0 0 0 6px",
            marginTop: "10px",
          }}
          severity="error"
          text={error}
        ></Message>
      )
    }
    return null
  }
  return (
    <div>
      <WorkflowListFilter workflowTemplates={workflowTemplates} query={query} onFilterChange={handleQueryChange} />
      {renderError()}
      <div>{query.fileSearch?.length >= 3 && <SearchLog fileSearchMatches={fileSearchMatch} />}</div>
      <WorkflowList workflowIds={workflowIds} />
    </div>
  )
}
export default WorkflowListPage
