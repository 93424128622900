import WorkflowMonitorList from "@/components/workflows/monitor/WorkflowMonitorList"
import Toolbar from "./Toolbar"

function WorkflowMonitor() {
  return (
    <div>
      <Toolbar />
      <WorkflowMonitorList />
    </div>
  )
}
export default WorkflowMonitor
